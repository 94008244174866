import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./../components/header";
import Footer from "../components/footer";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import { ApiUrl } from "../components/API/Api";

const MainLayout = () => {
  const [loading, setLoading] = useState(true);
  const [Homedata, setHomedata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ThreeDots
          visible={true}
          height="50"
          width="50"
          color="#001c38"
          ariaLabel="salem-diocese-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <>
      <Header menudata={Homedata?.headermenudata} />
      <div style={{ minHeight: "66.5vh", padding: "10px" }}>
        <Outlet />
      </div>
      <Footer footerdata={Homedata?.footercontactdata} />
    </>
  );
};

export default MainLayout;
