import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "./gallerystyle.css";

const Youtube = ({ newsletterData, gallerydata }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (gallerydata?.length > 0) {
      setLoading(false);
    }
  }, [gallerydata]);

  const images =
    gallerydata?.map((image) => ({
      original: image?.image || "",
      thumbnail: image?.image || "",
    })) || [];

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <div className="section-heading">
            <h2 className="entry-title">Gallery</h2>
          </div>
          {loading ? (
            <p>
              <b>Loading...</b>
            </p>
          ) : images.length === 0 ? (
            <p>
              <b>No gallery images available</b>
            </p>
          ) : (
            <ImageGallery items={images} />
          )}
        </div>
        <div className="col-md-6">
          <div className="section-heading">
            <h2 className="entry-title">Monthly Newsletter</h2>
          </div>
          <div className="scrollable-content" id="newsletterprog">
            {newsletterData.length > 0 ? (
              newsletterData.slice(0, 5).map((newsletter) => (
                <div
                  key={newsletter.id}
                  className="card shadow mb-2"
                  style={{ maxWidth: "585px", height: "100px" }}
                >
                  <div className="card-body text-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png"
                        alt="PDF Icon"
                        className="mb-3"
                        style={{ width: "50px" }}
                      />
                      <div className="text-center">
                        <h5 className="card-title mb-2">{newsletter.title}</h5>
                        <p className="card-text mb-3">{newsletter.eventdate}</p>
                      </div>
                      <a
                        href={newsletter.file_url}
                        className="btn btn-sm btn-primary"
                        download
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>
                <b>No Newsletter available</b>
              </p>
            )}

            {newsletterData?.length >= 5 && (
              <center>
                <Link
                  to={"/newsletter"}
                  style={{
                    backgroundColor: "#012c6d",
                    color: "white",
                    textDecoration: "none",
                    display: "inline-block",
                    padding: "8px 16px",
                    borderRadius: "5px",
                    marginTop: "10px",
                    fontSize: "14px",
                  }}
                >
                  View More
                </Link>
              </center>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Youtube;
